import React from 'react';
import "./footer.css"; // Custom CSS for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Company Info */}
        <div className="footer-section company-info">
          <h4 className="footer-heading">PDF Comment Extractor</h4>
          <p className="footer-text">
            Your go-to tool for extracting and organizing annotations and comments from PDFs effortlessly.
          </p>
        </div>

        {/* Quick Links */}
        <div className="footer-section quick-links">
          {/* <h4 className="footer-heading">Privacy</h4>
          <ul className="footer-links">
            <li><a href="#how-it-works">Terms & Conditions</a></li>
            <li><a href="#pricing">Privacy Policy</a></li>
          </ul> */}
        </div>
        {/* Social Media Links */}
        <div className="footer-section social-media">
          {/* <h4 className="footer-heading">Follow Us</h4>
          <div className="social-icons">
            <a href="https://www.instagram.com/thevoucheroo" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-instagram"></i>
            </a>
          </div> */}
        </div>
        {/* Social Media Links */}
        <div className="footer-section social-media">
          {/* <h4 className="footer-heading">Follow Us</h4>
          <div className="social-icons">
            <a href="https://www.instagram.com/thevoucheroo" target="_blank" rel="noopener noreferrer" className="social-icon">
              <i className="fab fa-instagram"></i>
            </a>
          </div> */}
        </div>

        {/* Contact Info */}
        <div className="footer-section contact-info">
          <h4 className="footer-heading">Contact Us</h4>
          <p className="footer-text">Email: support@thevoucheroo.com</p>
          <p className="footer-text">Gold Coast, Australia</p>
        </div>


      </div>

      <div className="footer-bottom">
        <div className='footer-terms'>
        <a href='/terms-and-conditions' className="footer-bottom-text terms">Terms & Conditions</a>
        <a href='/privacy' className="footer-bottom-text terms">Privacy Policy</a>
        </div>
        <p className="footer-bottom-text">&copy; 2024 Voucheroo. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
