import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './hero.css';
import PdfUploader from '../../components/pdfUploader';
import cover from '../../assets/images/cover.png';

const HeroSection = () => {
  const [showArrow, setShowArrow] = useState(false);

  return (
    <section className="hero-section">
      <div className="hero-container">
        {/* Left side: Drag and Drop Uploader */}
        <div className="uploader-section">
          {showArrow && (
            <a href="/" className="back-arrow">
              &#8592;
            </a>
          )}
          <h2 className="uploader-title">Upload Your PDF</h2>
          <p className="uploader-description">
            Easily extract comments and annotations from your PDFs and generate an excel report. Just drag and drop your file or click to upload.
          </p>
          <PdfUploader onFinish={() => setShowArrow(true)} />
        </div>

        {/* Right side: Image or Explanation */}
        <div className="image-section">
          <img
            src={cover}
            alt="PDF Illustration"
            className="pdf-illustration"
          />
          <p className="image-description">
            See how your PDFs are transformed into structured data, making it easier to review comments and share insights.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
