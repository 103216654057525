import React from 'react';
import "./faq.css"; // Custom CSS for styling
import faqImage from '../../assets/images/faq.png'; // Make sure to update the path to your image

const FAQSection = () => {
  return (
    <section className="faq-section">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <div className="faq-container">
        {/* Image on the left */}
        <div className="faq-image-container">
          <img src={faqImage} alt="FAQ Illustration" className="faq-image" />
        </div>

        {/* FAQ text on the right */}
        <div className="faq-text-container">
          <div className="faqs">
            
            {/* FAQ 1 */}
            <div className="faq">
              <div className="faq-question">How do I upload a PDF?</div>
              <div className="faq-answer">
                To upload your PDF, you can either drag and drop your file into the upload area or use the click-to-upload feature to select your file from your device.
              </div>
            </div>

            {/* FAQ 2 */}
            <div className="faq">
              <div className="faq-question">What happens after I upload my PDF?</div>
              <div className="faq-answer">
                Once your PDF is uploaded, our system automatically processes the file by extracting all the comments and annotations. You can track the progress of the extraction in real-time.
              </div>
            </div>

            {/* FAQ 3 */}
            <div className="faq">
              <div className="faq-question">How do I download the report?</div>
              <div className="faq-answer">
                When the extraction is complete, you can download your report in either XML or Excel format. The report will have all the annotations neatly organized for easy review and management.
              </div>
            </div>

            {/* FAQ 4 */}
            <div className="faq">
              <div className="faq-question">Is my data secure?</div>
              <div className="faq-answer">
                Yes, your privacy is extremely important to us. All uploaded files are securely handled, and we do not share or store your data after processing. Once your report is generated and downloaded, your PDF and associated data are removed from our servers.
              </div>
            </div>

            {/* FAQ 5 */}
            <div className="faq">
              <div className="faq-question">Can I use this tool for sensitive documents?</div>
              <div className="faq-answer">
                Our system uses secure file handling practices to ensure your documents are kept private. However, if your documents contain highly sensitive information, we recommend consulting your organization's security guidelines before use.
              </div>
            </div>

            {/* FAQ 6 */}
            <div className="faq">
              <div className="faq-question">What formats are supported for the output?</div>
              <div className="faq-answer">
                The processed annotations and comments can be downloaded in XML or Excel format, making it easy to integrate with your project management tools.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
