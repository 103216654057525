import React from 'react';
import "./payment.css"; 
import kangarooImage from '../../assets/images/kangaroo.png';

const PaymentSection = () => {
  return (
    <div className="payment-section">
      <div className="payment-content">
        {/* Image on the Left */}
        {/* Text on the Right */}
        <div className="payment-text">
          <h2 className="payment-heading">Pricing</h2>
          <p className="payment-description">
            Our PDF Comment Extractor is completely free to use. We believe in
            providing high-quality tools to the community without hidden fees or costs. However,
            if you’d like to support further development and maintenance of this app, you can
            contribute or learn more at Voucheroo.
          </p>
          <p className="payment-description">
            This project is proudly maintained by{" "}
            <a
              href="https://thevoucheroo.com"
              target="_blank"
              rel="noopener noreferrer"
              className="voucheroo-link"
            >
              Voucheroo
            </a>
            , an innovative platform connecting businesses and customers through exclusive offers.
          </p>
        </div>
        <div className="payment-image">
          <img
            src={kangarooImage}
            alt="Payment and Costs"
            className="payment-illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentSection;
