import axios, { AxiosHeaders } from 'axios';
import { signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

// Create an axios instance with default configuration
const instance = axios.create({
  baseURL: 'https://api.thevoucheroo.com', // Use config file to set base URL
  timeout: 99900000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Request Interceptor to manage authentication
instance.interceptors.request.use(
  async (requestConfig) => {
     const requiresAuth = false;

    if (requiresAuth) {
      // Assert that the user is authenticated
      await assertAuthentication();

      // Fetch session tokens using `fetchAuthSession`
      const session = await fetchAuthSession();
      const idToken = session?.tokens?.idToken ?? null;

      if (idToken) {
        // Ensure headers are an instance of AxiosHeaders and use `set` method
        if (!requestConfig.headers) {
          requestConfig.headers = new AxiosHeaders();
        }

        // Use the `set` method to update the Authorization header
        requestConfig.headers.set('Authorization', `Bearer ${idToken}`);
      }
    }

    if (requestConfig.data instanceof FormData) {
      delete requestConfig.headers['Content-Type'];
    }

    return requestConfig;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor for Error Handling
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      console.warn('Unauthorized, logging out...');
      try {
        await signOut();
      } catch (signOutError) {
        console.error('Error during sign-out: ', signOutError);
      }
    }

    return Promise.reject(error);
  }
);

// Function to check and assert user authentication
const assertAuthentication = async () => {
  try {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      await signOut();
      return;
    }
  } catch (error) {
    console.error('User not authenticated, redirecting to sign out...', error);
    try {
      await signOut();
    } catch (error) {
      console.error(error);
    }
    
  }
};

// Centralized function to handle API responses
const responseBody = (response) => response.data;

// Public and Private API Request Handlers
const requests = {
  // Public Endpoints (No Authentication Required)
  public: {
    get: (url, params, signal) => instance.get(url, { params, signal }).then(responseBody),
    post: (url, body, signal) => instance.post(url, body, { signal }).then(responseBody),
    // put: (url, body, headers = {}, signal) => 
    //   instance.put(url, body, { headers, ...(signal ? { signal } : {}) }).then(responseBody),
    put: (url, body, headers = {}, signal, onUploadProgress) => 
      instance.put(url, body, { 
        headers, 
        onUploadProgress: (progressEvent) => {
          const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
          // console.log(`Upload progress: ${percentComplete.toFixed(2)}%`);
          if (onUploadProgress) {
            onUploadProgress(percentComplete);
          }
        },
        ...(signal ? { signal } : {})
      }).then(responseBody),
  },

  // Authenticated Endpoints (Authentication Required)
  private: {
    get: (url, params, signal) => instance.get(url, { params, signal }).then(responseBody),
    post: (url, body, signal) => instance.post(url, body, { signal }).then(responseBody),
    put: (url, body, signal) => instance.put(url, body, { signal }).then(responseBody),
    patch: (url, body, signal) => instance.patch(url, body, { signal }).then(responseBody),
    delete: (url, body, signal) => instance.delete(url, { data: body, signal }).then(responseBody),
  }
};

export default requests;
