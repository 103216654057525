import React, { useEffect } from 'react';
import './privacy.css'

const Privacy = () => {
  useEffect(() => {
    document.title = 'Task Forge - Privacy';
  }, []);

  return (
    <div className="terms-container">
      <h1>Privacy Policy</h1>
      <p>
        <br />1. Introduction
        <br />
        <br />Welcome to Voucheroo (“Company,” “we,” “us,” or “our”). We are committed to safeguarding your privacy and ensuring that your personal information is handled in a secure and responsible manner. This Privacy Policy describes how we collect, use, store, and disclose information that you provide or that we collect through your use of our services, including but not limited to our website, mobile applications, and any other related platforms (collectively, the “Services”). By accessing or using our Services, you consent to the data practices described in this policy.
        <br />
        <br />2. Information We Collect
        <br />
        <br />We collect and process different types of information to operate our Services efficiently. This includes, but is not limited to:
        <br />
        <br />Personal Information: Identifiable information you voluntarily provide when registering, using our Services, or communicating with us, such as your name, email address, phone number, company affiliation, and payment details.
        <br />Uploaded Content: Any data, documents, or files, including but not limited to PDF documents, images, and annotations, that you upload to our platform. By uploading content, you grant Voucheroo a perpetual, irrevocable, worldwide, royalty-free license to use, store, modify, distribute, and commercialize this content in any form.
        <br />Generated Content: Data produced through our Services, such as comments extracted from PDFs, reports, and other processed information.
        <br />Usage Information: Automatically collected data about your interactions with our Services, including your IP address, device type, browser version, operating system, referral URLs, and information on how you navigate and interact with our platform.
        <br />Cookies and Tracking Technologies: We utilize cookies and similar technologies to track your activity and personalize your experience. Cookies are small text files stored on your device to remember your preferences and improve our Services.
        <br />
        <br />3. How We Use Your Information
        <br />
        <br />We use the information we collect for various legitimate business purposes, including but not limited to:
        <br />
        <br />Service Delivery: To provide, maintain, and improve our Services, including the processing and extraction of comments from PDFs, generating reports, and facilitating your user experience.
        <br />Data Analysis and AI Model Training: Your uploaded content and generated data may be used to train, test, and improve our artificial intelligence models and algorithms, and to develop new tools and features.
        <br />Marketing and Communications: To send promotional materials, newsletters, and advertisements tailored to your interests, and to analyze the effectiveness of our marketing campaigns. You can opt-out of marketing communications at any time.
        <br />Business Operations: For administrative, security, and operational purposes, such as troubleshooting, protecting against fraud, and ensuring the functionality of our Services.
        <br />Legal Compliance: To comply with applicable laws, regulations, or legal processes, and to enforce our terms of service and other agreements.
        <br />
        <br />4. Data Ownership and Rights
        <br />
        <br />By using our Services and uploading content, you agree that Voucheroo has full rights to use and control the data. We reserve the right to use, transfer, sell, or process your data, including uploaded PDFs and generated outputs, for any purpose, including but not limited to:
        <br />
        <br />Research and Development: Enhancing our products and conducting data analysis to improve our Services.
        <br />Commercial Use: Licensing, selling, or distributing data to third parties for research, marketing, or other commercial purposes.
        <br />AI Training: Using your data to train and refine artificial intelligence and machine learning models.
        <br />
        <br />5. Data Sharing and Disclosure
        <br />
        <br />We may share your information with third parties in the following circumstances:
        <br />
        <br />Service Providers: We engage trusted third-party vendors to assist in various business operations, such as data hosting, cloud services, analytics, payment processing, and marketing.
        <br />Business Affiliates and Partners: We may share data with our corporate affiliates, joint venture partners, or other companies under common ownership to better serve your needs or for co-branded marketing activities.
        <br />Corporate Transactions: Your data may be disclosed as part of a merger, acquisition, or sale of assets, or in the unlikely event of bankruptcy.
        <br />Legal Obligations: We may disclose information if required to do so by law, in response to lawful requests by public authorities, or to protect the rights, property, or safety of Voucheroo, our users, or the public.
        <br />
        <br />6. Data Security
        <br />
        <br />We employ commercially reasonable security measures to protect your information from unauthorized access, use, or disclosure. These measures include encryption, firewalls, and secure access controls. However, no method of data transmission or storage is 100% secure. By using our Services, you acknowledge that we cannot guarantee the absolute security of your data and agree that Voucheroo shall not be held liable for any unauthorized access, data breaches, or security incidents.
        <br />
        <br />7. Data Retention
        <br />
        <br />We retain your information for as long as necessary to provide our Services and fulfill the purposes outlined in this policy. This may include keeping your data even after you cease using our Services, as needed to comply with legal obligations, resolve disputes, or enforce our agreements.
        <br />
        <br />8. Your Rights and Choices
        <br />
        <br />Depending on your location and applicable laws, you may have rights related to your personal data, including:
        <br />
        <br />Access: Requesting access to your personal information and a copy of the data we hold about you.
        <br />Correction: Requesting corrections to any inaccurate or incomplete data.
        <br />Deletion: Requesting the deletion of your personal data under certain conditions.
        <br />Data Portability: Requesting a structured, commonly used, and machine-readable format of your data.
        <br />Object and Restrict: Objecting to the processing of your data or requesting restrictions on how we use your information.
        <br />To exercise your rights, please contact us at [Contact Information]. We may request proof of identity and reserve the right to deny requests that are unfounded, excessive, or prohibited by law.
        <br />
        <br />9. Cross-Border Data Transfers
        <br />
        <br />Your data may be transferred to and stored on servers in countries other than your own. By using our Services, you consent to the transfer of your information to countries with data protection laws that may differ from your jurisdiction.
        <br />
        <br />10. Children's Privacy
        <br />
        <br />Our Services are intended for individuals aged 18 and older. We do not knowingly collect personal information from children under the age of 18. If we become aware of any such data, we will take steps to delete it.
        <br />
        <br />11. Third-Party Links
        <br />
        <br />Our Services may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties. We encourage you to review the privacy policies of any external sites you visit.
        <br />
        <br />12. Changes to This Privacy Policy
        <br />
        <br />We reserve the right to update or modify this Privacy Policy at any time. When we do, we will post the updated version on our website and update the "Last Updated" date. Your continued use of our Services signifies your acceptance of any changes.
        <br />
        <br />13. Limitation of Liability
        <br />
        <br />By using our Services, you agree that Voucheroo will not be held liable for any direct, indirect, incidental, or consequential damages arising from your use of our Services or any data breaches, hacks, or security incidents, even if Voucheroo has been advised of the possibility of such damages. Your sole remedy for dissatisfaction with our Services is to discontinue use.
        <br />
        <br />14. Governing Law and Jurisdiction
        <br />
        <br />This Privacy Policy shall be governed by and construed in accordance with the laws of Queensland, Australia, without regard to its conflict of law principles. Any disputes arising out of or relating to this Privacy Policy shall be resolved in the courts of Queensland, Australia.
        <br />
        <br />15. Contact Us
        <br />
        <br />If you have questions, concerns, or complaints regarding this Privacy Policy, please contact us at:
        <br />
        <br />Voucheroo support@thevoucheroo.com
        <br />
        <br />By using our Services, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
      </p>
    </div>
  );
};

export default Privacy;
