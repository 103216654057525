import React, { useEffect } from 'react';
import HeroSection from './hero';
import StepInstructions from './stepInstructions';
import HowToSection from './howto';
import FeaturesSection from './features';
import FaqSection from './faq';
import PaymentSection from './payment';

const Home = () => {
  useEffect(() => {
    document.title = 'Task Forge';
  }, []);

  return (
    <div>
      <main className="main-content">
        <HeroSection />
        <StepInstructions />
        <HowToSection />
        <FeaturesSection />
        <FaqSection />
        <PaymentSection />
      </main>
    </div>
  );
};

export default Home;

