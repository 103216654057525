import React from 'react';
import "./howto.css"; // Custom CSS for styling
import Counter from '../../components/counter';

const HowToSection = () => {
  return (
    <section className="how-to-section">
      <div className="how-to-container">
        {/* Left Side - Instructions */}
        <div className="instructions">
          <h2 className="instructions-title">Is it for me?</h2>
          <ol className="steps-list">
            <li className="step">
              {/* <strong>1.</strong> */}
              Perfect for project managers, contractors, architects, and engineers who need to manage and track PDF comments efficiently.
            </li>
            <li className="step">
              {/* <strong>2.</strong> */}
              Main contractors often leave critical annotations for subcontractors, which need to be turned into actionable tasks.
            </li>
            <li className="step">
              {/* <strong>3.</strong> */}
              Employees and contractors typically spend 3 hours per project manually extracting comments. With our tool, that time drops to just minutes.
            </li>
            <li className="step">
              {/* <strong>4.</strong> */}
              Teams can save 40+ hours per week, freeing up time for more important work like planning and design.
            </li>
            <li className="step">
              {/* <strong>5.</strong> */}
              Focus on what matters. Automate tedious tasks and boost productivity effortlessly.
            </li>
          </ol>
          {/* <button className="generate-button">Extract Annotations</button> */}
        </div>
        {/* Right Side - Illustration */}
        <div className="illustration">
          <Counter />
        </div>
      </div>
    </section>
  );
};

export default HowToSection;
