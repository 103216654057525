import React from 'react';
import "./features.css";
import fast from '../../assets/gifs/timer.gif';
import downloadable from '../../assets/gifs/downloadable.gif';
import accurate from '../../assets/gifs/accurate.gif';
import easy from '../../assets/gifs/interface.gif';
import LazyImage from '../../components/lazyImage';

const FeaturesSection = () => {
  return (
    <section className="features-section">
      <div className="features-container">
        <h2 className="features-title">Why Choose Our PDF Comment Extractor?</h2>
        <div className="features-grid">
          {/* Feature 1 */}
          <div className="feature-card">
            <div className="feature-icon">
              <LazyImage src={fast} alt="Fast Processing" />
            </div>
            <h3 className="feature-heading">Fast & Efficient</h3>
            <p className="feature-description">
              Our advanced algorithms ensure that your PDFs are processed and extracted quickly, saving you time and effort.
            </p>
          </div>

          {/* Feature 2 */}
          <div className="feature-card">
            <div className="feature-icon">
              <LazyImage src={accurate} alt="Accurate Annotations" />
            </div>
            <h3 className="feature-heading">Accurate Annotations</h3>
            <p className="feature-description">
              Extract comments and annotations with precision, ensuring that no important details are missed.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="feature-card">
            <div className="feature-icon">
              <LazyImage src={easy} alt="User-Friendly Interface" />
            </div>
            <h3 className="feature-heading">User-Friendly Interface</h3>
            <p className="feature-description">
              Our intuitive drag-and-drop uploader makes it easy for anyone to start extracting annotations right away.
            </p>
          </div>

          {/* Feature 4 */}
          <div className="feature-card">
            <div className="feature-icon">
              {/* <LazyImage src={downloadable} alt="Downloadable Reports" /> */}
              <LazyImage src={downloadable} alt="Downloadable Report" />
            </div>
            <h3 className="feature-heading">Downloadable Reports</h3>
            <p className="feature-description">
              Export your extracted data into well-formatted XML or Excel reports, perfect for documentation or sharing.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
