import React, { useEffect, useState } from 'react';
import './counter.css'; // Make sure to style it appropriately

const Counter = () => {
  const [hours, setHours] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = 60000; // The target number
    const duration = 3000; // Duration of the animation in milliseconds
    const increment = end / (duration / 16); // Calculate the increment per frame (assuming ~60fps)

    const counter = setInterval(() => {
      start += increment;
      if (start >= end) {
        clearInterval(counter);
        setHours(end); // Set to the final number when complete
      } else {
        setHours(Math.floor(start));
      }
    }, 16); // 16ms for ~60fps

    return () => clearInterval(counter);
  }, []);

  return (
    <div>
      <div className="hours-counter">{hours}+</div>
      <div className="hours-label">Hours Saved</div>
    </div>
  );
};

export default Counter;
