import React, { useEffect } from 'react';
import './header.css'; // Custom CSS for the header

const Header = () => {
  useEffect(() => {
    const logo = document.querySelector('.logo-text');
    const text = 'Task Forge';
    let index = 0;

    const interval = setInterval(() => {
      if (index < text.length) {
        const span = logo.querySelectorAll('span')[index];
        if (span) {
          span.style.color = index >= 5 ? '#30D5C8' : 'black'; // "Forge" is colored, "Task" is black
        }
        index++;
      } else {
        clearInterval(interval);
      }
    }, 150); // Adjust speed of the animation

    return () => clearInterval(interval);
  }, []);

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          {/* <a href="/">Task Forge</a> */}
          {/* #30D5C8 */}
          <a href="/" className="logo-text">
            {/* Wrap each letter in a span for animation */}
            {'Task Forge'.split('').map((char, index) => (
              <span key={index}>{char}</span>
            ))}
          </a>
        </div>
        <nav className="nav-links">
          {/* <a href="/" className="nav-link">Home</a> */}
          {/* <a href="/about" className="nav-link">About</a> */}
          {/* <a href="/contact" className="nav-link">Contact</a> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
