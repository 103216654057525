import React, { useState, useEffect } from 'react';
import requests from "../services/httpService";
import { FaSync } from 'react-icons/fa';
import "./pdfUploader.css"; // Add custom CSS for styling

const PdfUploader = ({ onFinish }) => {
  const time = 3;
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [xmlUrl, setXmlUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [timer, setTimer] = useState(3);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    const progressUpdate = async () => {
      await getProgress();
    }
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else if (refreshing) {
      setRefreshing(false);
      if (id && !['completed', 'failed'].includes(status)) {
        progressUpdate();
      }
    }
  }, [timer]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const clear = () => {
    setId(null);
    setStatus(null);
    setFileUploadProgress(0);
    setProgress(0);
    setFile(null);

    setUploadMessage('');
    setXmlUrl('');
    setUploading(false);
    setRefreshing(false);
    setTimer(3);
    setEmail('');
    setEmailError('');
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle file selection via input
  const handleFileChange = (e) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setFile(selectedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!uploading) {
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile && droppedFile.type === 'application/pdf') {
        setFile(droppedFile);
      }
    }
  };

  // Function to remove the selected file and reset the flow
  const handleRemoveFile = () => {
    setFile(null);
    setId(null);
    setStatus(null);
    setProgress(0);
    setUploadMessage('');
    setXmlUrl('');
  };

  // Fetch progress from the backend
  const getProgress = async (responseId) => {
    if (timer > 0 || !(id || responseId)) return;
    try {
      setRefreshing(true);
      setTimer(time);
      const processResponse = await requests.public.post('/pdf', {
        operation: 'progress',
        id: id || responseId
      });
      // TODO: mock
      // const processResponse = {
      //   status: progress < 100 ? 'PROCESSING' : 'SUCCEEDED',
      //   progress: progress < 100 ? progress + 10 : progress,
      //   downloadUrl: progress < 100 ? null : 'https://www.test.com'
      // }
      if (!processResponse) {
        return;
      }

      if (processResponse.status) {
        setStatus(processResponse.status);
        if (['completed', 'failed'].includes(processResponse.status)) {
          onFinish();
        }
      }

      if (processResponse.progress) {
        setProgress(processResponse.progress);
      }

      if (processResponse.downloadUrl) {
        setXmlUrl(processResponse.downloadUrl);
      }

    } catch (error) {
      console.error('Error fetching progress:', error);
      setUploadMessage('Error fetching progress');
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload');
      return;
    }

    if (!email || !isValidEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    setEmailError('');

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      setUploadMessage('Requesting server information...');

      const presignedUrlResponse = await requests.public.post('/pdf', {
        operation: 'generate_url',
        filename: file.name.toLowerCase(),
        email
      });

      setUploadMessage('Validating...');
      const presignedUrl = presignedUrlResponse.url;
      const responseId = presignedUrlResponse.id;
      if (!presignedUrl || !responseId) {
        throw new Error('Failed to obtain presigned URL');
      }

      setId(responseId);

      setUploadMessage('Uploading file...');
      // TODO
      await requests.public.put(presignedUrl, file,
        { 'Content-Type': 'application/pdf' },
        null,
        (progressEvent) => {
          // Update your UI with the upload progress
          setFileUploadProgress(progressEvent);
        }
      );
      const processResponse = await requests.public.post('/pdf', {
        operation: 'process_pdf',
        id: responseId
      });

      if (processResponse?.error) {
        throw new Error(processResponse.error);
      }

      setStatus('queued');
      setProgress(0);
      setUploadMessage('');
      if (responseId) {
        await getProgress(responseId);
      }
    } catch (error) {
      onFinish();
      setUploading(false);
      setStatus(null);
      setFileUploadProgress(0);
      setProgress(0);
      console.error('Error processing file:', error);
      setUploadMessage('Error processing file');
    }
  };

  return (
    <div className="pdf-uploader-container">
      <div
        className={`dd-section ${uploading ? 'disabled' : ''}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="upload-box">
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            className="file-input"
            disabled={uploading}
          />
          <p className="drag-and-drop-text">
            {file ? (
              <span className="file-name">
                {file.name}
                {!status && !id &&
                  <button className="remove-file-button" onClick={handleRemoveFile} disabled={uploading}>
                    &times;
                  </button>
                }
              </span>
            ) : (
              'Drag & Drop your PDF file here or click to select'
            )}
            {uploading && !status &&
              <div className="spinner-container">
                <div className="spinner"></div>
                <p>{uploadMessage}</p>
                <p>Please do not refresh you browser, it might take some time to upload large files.</p>
              </div>
            }

            {fileUploadProgress > 0 && fileUploadProgress < 100 &&
              <div className="file-upload-bar-container">
                <div className="file-upload-bar" style={{ width: `${fileUploadProgress}%` }}></div>
                <span className="file-upload-progress-text">{fileUploadProgress}%</span>
              </div>
            }
          </p>
        </div>
        {!uploading && file && (
          <div className="email-section">
            <p className="subtitle">We will notify you by email when your file is processed and ready to download.</p>
            <input
              type="email"
              placeholder="Where should we notify you?"
              value={email}
              onChange={handleEmailChange}
              className="email-input"
            />
            {emailError && <p className="email-error">{emailError}</p>}
            <button onClick={handleUpload} className="upload-button" >
              Generate Progress Tracker
            </button>
          </div>
        )}
      </div>

      {status === 'started' &&
        <div className="spinner-container">
          <div className="spinner"/>
          <p>Please wait, encrypting the file.</p>
        </div>
      }

      {((uploading && status) || (status && id)) && (
        <div className="status-bar-container">
          <div className="status-bar">
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
          <span className="progress-text">{progress}%</span>
          {/* Refresh icon */}
          {/* {!xmlUrl && status !== 'failed' && */}
          {/* <button className="refresh-button" onClick={getProgress} disabled={refreshing}>
              <FaSync className={refreshing ? "spinning" : ""} />
            </button> */}
          {/* } */}
        </div>
      )}

      {status === 'failed' &&
        <div className="status-section">
          {status && !xmlUrl && <p className="status-text"><strong>Status:</strong> {status}</p>}
          {status && !xmlUrl && <p className="status-text">
            Something went wrong, we failed to process your pdf. Please try again later.<br />
            Ensure your file is not password protected and that the file is not too large.
          </p>}
        </div>
      }

      {((uploading || (status || id))) && status !== 'failed' && (
        <div className="status-section">
          {status && !xmlUrl && <p className="status-text"><strong>Status:</strong> {status == 'started' ? 'starting' : status}</p>}
          {status && !xmlUrl && <p className="status-text">
            Processing might take up to couple of minutes. Ocasionally it might take longer especially for large pdf files.
            Press refresh icon to check on the status.<br /><br />Don't worry, we will notify you by email once the file is ready.
          </p>}
        </div>
      )}


      {xmlUrl && status !== 'failed' && (
        <div className="download-section">
          <h2 className="download-title">Your file is now ready.</h2>
          <a href={xmlUrl} target="_blank" rel="noopener noreferrer" className="download-link">
            Download Excel File
          </a>
        </div>
      )}
    </div>
  );
};

export default PdfUploader;
