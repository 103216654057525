import React from 'react';
import './stepInstructions.css'; // Custom CSS for styling
import step1 from '../../assets/gifs/upload.gif';
import step2 from '../../assets/gifs/extract.gif';
import step3 from '../../assets/gifs/download.gif';
import LazyImage from '../../components/lazyImage';

// Horizontal step instructions
const StepInstructions = () => {
  // Array of steps with images and text
  const steps = [
    {
      imgSrc: step1,
      title: 'Step 1: Upload Your PDF',
      description: 'Upload your PDF containing annotations or comments for processing.',
    },
    {
      imgSrc: step2,
      title: 'Step 2: Extract Comments',
      description: 'Our system extracts all annotations and comments for easy review and progress tracking.',
    },
    {
      imgSrc: step3,
      title: 'Step 3: Download Excel Report',
      description: 'Get your organized XLS report and manage your progress efficiently.',
    },
  ];

  return (
    <section className="step-instructions-section">
      <h2 className="step-instructions-title">How to Use This Tool</h2>
      <div className="step-instructions-container">
        {steps.map((step, index) => (
          <div key={index} className="step-item">
            <LazyImage src={step.imgSrc} alt={step.title} className="step-image" />
            <h3 className="step-title">{step.title}</h3>
            <p className="step-description">{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StepInstructions;
