import React, { useEffect } from 'react';
import './terms.css'

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Task Forge - Terms And Conditions';
  }, []);

  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p>
        <br />
        <br />Welcome to Voucheroo (“Company,” “we,” “us,” or “our”). By accessing or using our services, including but not limited to our website, mobile applications, and related platforms (collectively, the “Services”), you agree to be bound by these Terms and Conditions. Please read these Terms carefully. If you do not agree, you must not use our Services.
        <br />
        <br />1. Acceptance of Terms
        <br />By accessing or using our Services, you agree to be bound by these Terms and all applicable laws and regulations. If you disagree with any part of these Terms, you may not access or use our Services.
        <br />
        <br />2. Changes to Terms
        <br />We reserve the right to update, modify, or replace any part of these Terms at our sole discretion. Changes will be effective immediately upon posting on our website. Your continued use of our Services following any such changes constitutes acceptance of the new Terms.
        <br />
        <br />3. Use of Services
        <br />You agree to use our Services only for lawful purposes and in accordance with these Terms. You are prohibited from:
        <br />
        <br />Using our Services in any way that breaches any applicable local, national, or international law or regulation.
        <br />Exploiting, harming, or attempting to exploit or harm minors in any way.
        <br />Sending, knowingly receiving, uploading, downloading, or using any material that does not comply with our content standards.
        <br />Transmitting or procuring the sending of any unauthorized or unsolicited advertising or promotional material.
        <br />Engaging in any activity that could harm or disrupt the proper functioning of our Services.
        <br />
        <br />4. User Content and Ownership
        <br />4.1 Content You Upload
        <br />By uploading or submitting content (such as PDFs, images, or any data) to our platform, you grant Voucheroo a perpetual, irrevocable, worldwide, royalty-free license to use, store, reproduce, modify, distribute, display, and create derivative works from your content. You warrant that you have the necessary rights to upload and share this content and that it does not infringe on any third-party rights.
        <br />
        <br />4.2 Generated Content
        <br />Any content generated by our Services (e.g., comments extracted from PDFs, reports, or other processed outputs) is the property of Voucheroo. We reserve the right to use, share, and commercialize such content without limitation.
        <br />
        <br />5. Intellectual Property Rights
        <br />All intellectual property rights in our Services, including but not limited to text, graphics, logos, icons, and software, are owned by Voucheroo or our licensors. You are granted a limited, non-exclusive, non-transferable, and revocable license to use our Services for personal or business purposes. You must not:
        <br />
        <br />Copy, modify, or create derivative works based on our content.
        <br />Use our trademarks without prior written consent.
        <br />Reverse-engineer or attempt to extract the source code of our software.
        <br />
        <br />6. Data Collection and Use
        <br />We collect, store, and process your data in accordance with our Privacy Policy. By using our Services, you acknowledge and consent to our data practices, which include the right to use, transfer, or sell your data. This data may be used for research, AI model training, or other commercial purposes.
        <br />
        <br />7. Privacy and Security
        <br />While we employ commercially reasonable security measures to protect your data, you acknowledge that no method of data transmission or storage is completely secure. Voucheroo cannot guarantee the absolute security of your data and will not be liable for any unauthorized access, data breaches, or loss of information. You use our Services at your own risk.
        <br />
        <br />8. Data Ownership and Rights
        <br />By uploading content, you agree that Voucheroo has full rights to use and control the data. We may:
        <br />
        <br />Transfer, sell, or process your data, including for commercial purposes.
        <br />Use your data to improve our products and train AI models.
        <br />Share your data with third-party partners for research, marketing, or operational needs.
        <br />
        <br />9. Third-Party Services
        <br />Our Services may include links to third-party websites or services. We are not responsible for the content, privacy policies, or practices of these third parties. You access third-party sites at your own risk.
        <br />
        <br />10. Service Availability and Modifications
        <br />We do not guarantee that our Services will always be available or uninterrupted. We may suspend, withdraw, or restrict the availability of all or any part of our Services for business or operational reasons without notice.
        <br />
        <br />11. Disclaimers
        <br />Our Services are provided “as is” and “as available,” without any warranties of any kind. Voucheroo disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that:
        <br />
        <br />Our Services will be error-free or uninterrupted.
        <br />The results obtained from using our Services will be accurate or reliable.
        <br />Any defects will be corrected.
        <br />12. Limitation of Liability
        <br />To the maximum extent permitted by law, Voucheroo will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, or goodwill. Our liability for any claims under these Terms is limited to the amount you paid us for the use of our Services.
        <br />
        <br />13. Indemnification
        <br />You agree to indemnify and hold harmless Voucheroo, its affiliates, employees, and agents from and against any claims, liabilities, damages, judgments, losses, costs, or expenses arising from your use of our Services or your violation of these Terms.
        <br />
        <br />14. Termination
        <br />We reserve the right to terminate or suspend your access to our Services immediately, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use our Services will cease immediately.
        <br />
        <br />15. Governing Law and Jurisdiction
        <br />These Terms are governed by and construed in accordance with the laws of Queensland, Australia. Any legal action or proceeding arising under these Terms shall be brought exclusively in the courts located in Queensland, Australia.
        <br />
        <br />16. Severability
        <br />If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions will remain in effect. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
        <br />
        <br />17. Entire Agreement
        <br />These Terms constitute the entire agreement between you and Voucheroo regarding our Services and supersede any prior agreements.
        <br />
        <br />18. Contact Information
        <br />For questions or concerns regarding these Terms, please contact us at:
        <br />
        <br />Voucheroo
        <br />Email: support@thevoucheroo.com
        <br />Website: thevoucheroo.com
        <br />
        <br />By using our Services, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
      </p>
    </div>
  );
};

export default TermsAndConditions;
