import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import Header from './components/header';
import Home from './pages/Home/home';
import Footer from './components/footer';
import TermsAndConditions from './pages/Terms/terms';
import NotFound from './components/notFound';
import Privacy from './pages/Privacy/privacy';

Amplify.configure(
  {
    ...awsExports,
  }
);

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  )
}

export default App;
